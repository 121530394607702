/* SwitchControl.css */
.switch-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #6f42c1; /* Bootstrap's purple color */
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Optional: Add a transition effect for the slider */
  .slider {
    background-color: #ccc;
  }
  