.autocomplete-dropdown {
    position: relative;
    width: 300px;
    margin: 20px;
  }
  
  .input-container {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
  }
  
  .input-container input {
    border: none;
    flex: 1;
    outline: none;
  }
  
  .suggestions-list {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .suggestions-list li {
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestions-list li:hover {
    background-color: #f0f0f0;
  }
  
  .tag {
    background-color: #e0e0e0;
    border-radius: 3px;
    padding: 3px 6px;
    margin: 2px;
    display: flex;
    align-items: center;
  }
  
  .tag span {
    margin-right: 6px;
  }
  
  .tag .remove-tag {
    cursor: pointer;
    font-size: 12px;
    margin-left: 6px;
  }
  