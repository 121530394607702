.container {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
    background-color: #e5f6ff;
  }
  
  .header {
    background-color: #00c0f4;
    color: white;
    padding: 20px;
  }
  
  .header h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .announcement {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .left-side, .right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-content {
    background-color: #fff7e6;
    padding: 20px;
    border-radius: 10px;
  }
  
  .children {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .child {
    width: 100px;
    height: auto;
    margin: 0 10px;
  }
  
  .text-content {
    display: flex;
    justify-content: space-around;
  }
  
  .text-item {
    flex: 1;
    margin: 0 10px;
  }
  
  .text-item h2 {
    color: #00c0f4;
  }
  
  .icon {
    width: 50px;
    height: auto;
    margin: 10px 0;
  }
  
  footer {
    margin-top: 20px;
  }
  
  .header-image {
    width: 100%;
    display: block;
  }

  .btn-info {
    background-color: #5E17EB;
    border-color: #5E17EB;
    color: white;
  }
  
  .btn-info:hover, 
  .btn-info:focus, 
  .btn-info:active, 
  .btn-info.active, 
  .open > .dropdown-toggle.btn-info {
    background-color: darken(#5E17EB, 10%);
    border-color: darken(#5E17EB, 12%);
  }
  
  .btn-info:disabled, 
  .btn-info[disabled], 
  fieldset[disabled] .btn-info, 
  .btn-info.disabled, 
  .btn-info:disabled:hover, 
  .btn-info[disabled]:hover, 
  fieldset[disabled] .btn-info:hover, 
  .btn-info.disabled:hover {
    background-color: #5E17EB;
    border-color: #5E17EB;
  }