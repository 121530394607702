/* Custom Alertify.js Styles for Bootstrap Integration */
.alertify .ajs-button.ajs-ok {
    color: #fff;
    background-color: #6f42c1; /* Bootstrap info color */
    border-color: #6f42c1;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.alertify .ajs-button.ajs-ok:hover {
    background-color: #6f42c1; /* Darker shade of Bootstrap info color */
    border-color: #6f42c1;
}

.alertify .ajs-button.ajs-cancel {
    color: #fff;
    background-color: #6c757d; /* Bootstrap secondary color */
    border-color: #6c757d;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.alertify .ajs-button.ajs-cancel:hover {
    background-color: #5a6268; /* Darker shade of Bootstrap secondary color */
    border-color: #545b62;
}
