/* General grid layout */
.kit-grid {
    gap: 1rem;
    justify-content: space-between;
}

/* Tile container */
.kit-tile {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: #fff;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
}

/* Tile hover effect */
.kit-tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
}

/* Image styling */
.kit-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 0.75rem;
}

/* Title styling */
.kit-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
    color: #333;
}

/* Description styling */
.kit-description {
    font-size: 0.9rem;
    color: #666;
    margin: 0.5rem 0 0;
}
